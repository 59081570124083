import React, { useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import './productModal.scss';
import SideBySideMagnifier from '../SideBySideMagnifier';

import plusIcon from '../../../../images/plus.svg';
import minusIcon from '../../../../images/minus.svg';

function ProductModal({
  product,
  addToCart = () => {}
}) {
  const currentImageRef = useRef(null);
  const imagesContainerRef = useRef(null);
  const imageMouseDownInterval = useRef(null);
  const [quantity, setQuantity] = useState(1);
  const [currentProductImage, setCurrentProductImage] = useState({});
  const [currentProductImageIndex, setCurrentProductImageIndex] = useState(0);
  const [showOtherFiles, setShowOtherFiles] = useState(false);
  const [currentImageSelectionHeight, setCurrentImageSelectionHeight] = useState(undefined);

  useEffect(() => {
    if (product && product.media && product.media.length) {
      setCurrentProductImage(product.media[0]);
      setQuantity(1);
    }
  }, [product]);

  useEffect(() => {
    if (currentImageRef && currentImageRef.current && currentImageRef.current.clientHeight) {
      setCurrentImageSelectionHeight(currentImageRef.current.clientHeight);
    } else {
      setCurrentImageSelectionHeight(undefined);
    }
  }, [currentProductImage]);

  const renderCurrentMedia = () => {
    if (currentProductImage.contentType === 'video' && currentProductImage.url.includes('player.vimeo')) {
      const videoUrl = currentProductImage.url.split('.hd')[0];
      const videoUrlSegments = videoUrl.split('/');
      const videoId = videoUrlSegments[videoUrlSegments.length - 1];
      const options = [];

      if (currentProductImage.autoplay) {
        options.push('autoplay');
      }

      if (currentProductImage.muted) {
        options.push('muted', 'cc');
      }

      let query = `${options.length ? '?' : ''}`;

      options.forEach((option, i) => {
        if (i === 0) {
          query += `${option}=1`;
        } else {
          query += `&${option}=1`;
        }
      });

      return (
        <div className="videoContainerOuter" ref={currentImageRef}>
          <div className="videoContainer">
            <div className="videoInner">
              <iframe title={`add-video`} src={`https://player.vimeo.com/video/${videoId}${query}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div ref={currentImageRef} className="selectedImageContainer">
        <SideBySideMagnifier
          imageSrc={currentProductImage.url || product.media[0].url}
          imageAlt={currentProductImage.alt || product.media[0].alt}
          alwaysInPlace={false}
          fillAvailableSpace={false}
        />
      </div>
    );
  };

  const handleImageChange = (image, i) => {
    setCurrentProductImage(image);
    setCurrentProductImageIndex(i);
    setShowOtherFiles(true);
  };

  const handleImageContainerScroll = (goUp) => {
    const amountToScroll = goUp ? -5 : 5;

    if (imagesContainerRef && imagesContainerRef.current) {
      imagesContainerRef.current.scrollTop += amountToScroll;
    }
  };

  const handleImageArrowMouseDown = (goUp) => {
    handleImageContainerScroll(goUp);

    const mouseDownInterval = setInterval(() => {
      handleImageContainerScroll(goUp);
    }, 15);

    imageMouseDownInterval.current = mouseDownInterval;
  };

  const handleImageArrowMouseUp = () => {
    if (imageMouseDownInterval && imageMouseDownInterval.current && imageMouseDownInterval.current) {
      clearInterval(imageMouseDownInterval.current);
      imageMouseDownInterval.current = false;
    }
  };

  return (
    <div className="ProductModal">
      {(!product || !product.id) ? null :
        <div className="product-content">
          <div className="imageContainerOuter">
            <div className="imageContainer">
              <div className="selectedImage">
                {renderCurrentMedia()}
              </div>
              <div className="selectedImageMobile">
                <SwipeableViews index={currentProductImageIndex} onChangeIndex={(index) => { handleImageChange(product.media[index], index) }} enableMouseEvents>
                  {product.media.map((m, i) => {
                    if (!showOtherFiles && i !== 0) {
                      return <div key={`swipable-${i}`}></div>;
                    }

                    if (m.contentType === 'video' && m.url.includes('player.vimeo')) {
                      const videoUrl = m.url.split('.hd')[0];
                      const videoUrlSegments = videoUrl.split('/');
                      const videoId = videoUrlSegments[videoUrlSegments.length - 1];
                      const options = [];

                      if (m.autoplay) {
                        options.push('autoplay');
                      }

                      if (m.muted) {
                        options.push('muted', 'cc');
                      }

                      let query = `${options.length ? '?' : ''}`;

                      options.forEach((option, i) => {
                        if (i === 0) {
                          query += `${option}=1`;
                        } else {
                          query += `&${option}=1`;
                        }
                      });

                      return (
                        <div key={`swipable-${i}`} className="videoContainerOuter">
                          <div className="videoContainer">
                            <div className="videoInner">
                              <iframe title={`add-video`} src={`https://player.vimeo.com/video/${videoId}${query}`} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen width="100%" height="100%" name="fitvid0"></iframe>
                            </div>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <img
                        key={`swipable-${i}`}
                        src={m.url || product.media[0].url}
                        className="lazyload"
                        alt={product.name}
                      />
                    );
                  })}
                </SwipeableViews>
              </div>
              <div className="imageSelection" style={{height: currentImageSelectionHeight}}>
                <div className="imagesContainer" ref={imagesContainerRef}>
                  {product.media.map((image, i) => {
                    return (
                      <div className="mediaItemContainer" key={`product-image-${i}`}>
                        {image.contentType === 'video' ?
                          <div className="videoThumbnailContainer" onClick={() => { handleImageChange(image, i) }} style={{ border: '1px solid rgba(0, 0, 0, 0.1)', borderColor: !currentProductImage.url ? (i === 0 ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') }}>
                            <img height="50" src="https://pupford.com/images/play-icon.svg" className="playIcon lazyload" alt="play icon" />
                            <img src={image.placeholder} className="thumbnailImage lazyload" alt={image.alt}/>
                          </div> :
                          <img src={image.url} className="desktopImageThumbnail lazyload" alt={image.alt} onClick={() => { handleImageChange(image, i) }} style={{ borderColor: !currentProductImage.url ? (i === 0 ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#ff3312' : 'rgba(0, 0, 0, 0.1)') }}/>
                        }
                        <div className="imageSelectionButton" onClick={() => { handleImageChange(image, i) }} style={{ backgroundColor: !currentProductImage.url ? (i === 0 ? '#000' : 'rgba(0, 0, 0, 0.1)') : (image.storagePath === currentProductImage.storagePath ? '#000' : 'rgba(0, 0, 0, 0.1)') }}></div>
                      </div>
                    );
                  })}
                </div>

                <div className="imageArrowContainer">
                  <div className="imageArrow">
                    <div
                      className="imageArrowClickTarget"
                      onMouseDown={() => handleImageArrowMouseDown(true)}
                      onMouseUp={handleImageArrowMouseUp}
                      onMouseLeave={handleImageArrowMouseUp}
                    ></div>
                    <img alt="Scroll Up" src="https://pupford.com/images/caret-up.svg" />
                  </div>
                  <div className="imageArrow">
                    <div
                      className="imageArrowClickTarget"
                      onMouseDown={() => handleImageArrowMouseDown(false)}
                      onMouseUp={handleImageArrowMouseUp}
                      onMouseLeave={handleImageArrowMouseUp}
                    ></div>
                    <img alt="Scroll Down" src="https://pupford.com/images/caret-up.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="productDetailsContainer">
            <p className="product-name">{product.name}</p>

            {!product.categories.length ? null : <div className="categoriesContainer">
              {product.categories.map((category, i) => {
                return (
                  <div key={`category-${i}`} className="category">
                    <p>{category}</p>
                  </div>
                );
              })}
            </div>}

            {product.selectedSubscriptionProduct ? null :
              <>
                <div className="amountContainer">
                  <div className="amountContainerInner">
                    <div className="quantityButton">
                      <button
                        className="quantityButtonInner"
                        onClick={() => {
                          const updatedQuantity = +quantity - 1;

                          if (updatedQuantity === 0) {
                            return;
                          }

                          setQuantity(updatedQuantity);
                        }}
                      >
                        <img src={minusIcon} alt="add" width="15" />
                      </button>
                    </div>
                    <div className="quantityAmount">{quantity}</div>
                    <div className="quantityButton">
                      <button
                        className="quantityButtonInner"
                        onClick={() => {
                          const updatedQuantity = +quantity + 1;

                          if (updatedQuantity > product.availableQuantity) {
                            return;
                          }

                          setQuantity(updatedQuantity);
                        }}
                      >
                        <img src={plusIcon} alt="add" width="15" />
                      </button>
                    </div>
                  </div>
                </div>

                <p className="productMSRP">Available: {product.availableQuantity}</p>

                {(product.msrp && parseFloat(product.msrp) !== parseFloat(product.price)) ?
                  <p className="productMSRP">MSRP ${product.msrp}</p> :
                  null
                }

                <div className="buyRow">
                  <div className="priceLine">
                    <p className="productPrice">${product.price}</p>
                  </div>

                  <button onClick={() => {
                    addToCart(quantity);
                  }}>
                    Add to Cart
                  </button>
                </div>
              </>
            }

            <p className="description">{product.description}</p>
          </div>
        </div>
      }
    </div>
  );
}

export default ProductModal;
