import { combineReducers } from 'redux';

import UserReducer from './userReducer';
import ShoppingReducer from './shoppingReducer';
import ShopSettingsReducer from './shopSettingsReducer';

export default combineReducers({
  user: UserReducer,
  cart: ShoppingReducer,
  shopSettings: ShopSettingsReducer,
});
