import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import Auth from './auth';
import Home from './Home';
import Loading from './shared/Loading';
import {
  repopulateCart,
  changeQuantity,
  removeFromCart,
  setUser,
  updateUser,
} from '../store/actions';

function Main(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const cart = useSelector((state) => state.cart);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (result) => {
      let user = {};

      if (result && !result.isAnonymous) {
        user = result;
      }

      try {
        if (user) {
          const idTokenResult = await auth.currentUser.getIdTokenResult();

          if (!idTokenResult.claims || !idTokenResult.claims.roles || !idTokenResult.claims.roles.includes('wholesaler')) {
            user = {};
            await auth.signOut();
            history.push('/signin?authResult=UNAUTHORIZED');
          }
        }
      } catch (e) {
        user = {};
        await auth.signOut();
      }

      if (user.uid) {
        dispatch(updateUser(user));
        getShoppingCart(user.uid);
      } else {
        dispatch(setUser(user));
      }
      setInitialized(true);
    });
  }, []);

  const getShoppingCart = async (userId) => {
    if (cart.userId) {
      return;
    }

    const db = getFirestore();

    try {
      const userCartRef = await getDoc(doc(db, 'shopping-carts', userId));
      const cartData = userCartRef.data();

      if (cartData) {
        dispatch(repopulateCart({
          ...cartData,
          userId,
        }));
      }
    } catch (e) {
      return;
    }
  };

  const handleChangeQuantity = (i, quantity) => {
    dispatch(changeQuantity(i, quantity));
  };

  const handleRemoveFromCart = (i) => {
    dispatch(removeFromCart(i));
  };

  if (user.uid) {
    return (
      <Home
        cart={cart}
        changeQuantity={handleChangeQuantity}
        removeFromCart={handleRemoveFromCart}
        history={props.history}
      />
    );
  }

  if (initialized) {
    return <Auth />;
  }

  return <Loading />;
}

export default Main;
