import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';

import './products.scss';
import { addToCart } from '../../../store/actions';
import { config } from '../../../config';
import Loading from '../../shared/Loading';
import Modal from '../../shared/Modal';
import ProductModal from './ProductModal';

function Products(props) {
  const dispatch = useDispatch();
  const products = useSelector(state => state.shopSettings.products);
  const productsInitialized = useSelector(state => state.shopSettings.productsInitialized);
  const filters = useSelector(state => state.shopSettings.filters);
  const [loading, setLoading] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [results, setResults] = useState([]);
  const [resultsCount, setResultsCount] = useState(0);
  const [sort, setSort] = useState('featured');
  const [categories, setCategories] = useState([]);
  const [prices, setPrices] = useState([
    {
      display: 'Less than $10',
      low: 0,
      high: 10,
      selected: false,
    },
    {
      display: '$10 to $20',
      low: 10,
      high: 20,
      selected: false,
    },
    {
      display: '$20 to $30',
      low: 20,
      high: 30,
      selected: false,
    },
    {
      display: '$30 to $40',
      low: 30,
      high: 40,
      selected: false,
    },
    {
      display: '$40 to $50',
      low: 40,
      high: 50,
      selected: false,
    },
    {
      display: '$50 & Above',
      low: 50,
      high: 100000,
      selected: false,
    },
  ]);
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [resultsPerPage, setResultsPerPage] = useState(40);
  const [currentPage, setCurrentPage] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [customFilters, setCustomFilters] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [productModalOpen, setProductModalOpen] = useState(false);

  useEffect(() => {
    if (products.length && productsInitialized) {
      setTimeout(() => {
        initializeShop();
      }, 0);
    }
  }, [products, productsInitialized]);

  const initializeShop = async () => {
    try {
      const uniqueCategories = [];
      const mappedProducts = products.map((p, i) => {
        p.categories.forEach(c => {
          if (!uniqueCategories.includes(c)) {
            uniqueCategories.push(c);
          }
        });
  
        const filterPrice = !(p.isOnSale || p.lowestSubscriptionPrice) ? +p.price :
          (p.lowestSubscriptionPrice ? +p.lowestSubscriptionPrice : +p.salePrice);
  
        return {
          ...p,
          filterPrice,
          featuredIndex: i,
        };
      });

      const categoryArr = uniqueCategories.sort().map(c => {
        return {
          category: c,
          selected: false,
        };
      });
  
      const updatedFilters = filters.map(f => {
        const filterCopy = { ...f };
        filterCopy.options = filterCopy.options.map(o => {
          return {
            name: o,
            selected: false,
          };
        });
  
        return filterCopy;
      });

      setFilteredProducts(mappedProducts);
      setResultsCount(mappedProducts.length);
      setCategories(categoryArr);
      setCustomFilters(updatedFilters);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving wholesale products, please try again. If this problem persists, please contact us at hello@pupford.com for assistance.');
    }
  };

  useEffect(() => {
    const productsCopy = [ ...filteredProducts ];

    if (sort === 'featured') {
      productsCopy.sort((a, b) => {
        return a.featuredIndex - b.featuredIndex;
      });
    }

    if (sort === 'alphabetical') {
      productsCopy.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }

        if (a.name > b.name) {
          return 1;
        }

        return 0;
      });
    }

    if (sort === 'priceLowHigh') {
      productsCopy.sort((a, b) => {
        return +a.filterPrice - +b.filterPrice;
      });
    }

    if (sort === 'priceHighLow') {
      productsCopy.sort((a, b) => {
        return +b.filterPrice - +a.filterPrice;
      });
    }

    setFilteredProducts(productsCopy);
  }, [sort]);

  useEffect(() => {
    const selectedCategories = categories.filter(c => {
      return c.selected;
    }).map(c => {
      return c.category;
    });
    let productsCopy = filteredProducts.filter(p => {
      let hasCategory = false;
      let isWithinPriceRange = false;
      let passesCustomFilters = false;

      if (selectedCategories.length) {
        p.categories.forEach(c => {
          if (selectedCategories.includes(c)) {
            hasCategory = true;
          }
        });
      } else {
        hasCategory = true;
      }

      if (prices.find(c => c.selected)) {
        prices.forEach(price => {
          if (price.selected && p.filterPrice >= price.low && p.filterPrice < price.high) {
            isWithinPriceRange = true;
          }
        });
      } else {
        isWithinPriceRange = true;
      }

      const customFiltersBooleans = customFilters.map((cf, i) => {
        let passesThisFilter = false;

        if (cf.options.find(c => c.selected)) {
          cf.options.forEach(f => {
            if (f.selected) {
              if (!p.filters.length) {
                passesThisFilter = false;
              } else {
                const foundFilter = p.filters.find(productFilter => productFilter.id === cf.id);
  
                if (foundFilter && foundFilter.selected.includes(f.name)) {
                  passesThisFilter = true;
                }
              }
            }
          });
        } else {
          passesThisFilter = true;
        }

        return passesThisFilter;
      });

      const falseIndex = customFiltersBooleans.findIndex(f => f === false);

      if (falseIndex === -1) {
        passesCustomFilters = true;
      }

      return (hasCategory && isWithinPriceRange && passesCustomFilters);
    });

    setResultsCount(productsCopy.length);
    setNumberOfPages(Math.ceil(productsCopy.length / resultsPerPage));

    const skip = currentPage * resultsPerPage;

    productsCopy = productsCopy.slice(skip, skip + resultsPerPage);

    const productArrays = [[]];

    const viewItemListEvent = {
      event: 'view_item_list',
      ecommerce: {
        items: [],
      },
    };

    productsCopy.forEach((p, i) => {
      const lastProductArrayIndex = productArrays.length - 1;

      if (productArrays[lastProductArrayIndex].length === 2) {
        productArrays.push([p]);
      } else {
        productArrays[lastProductArrayIndex].push(p);
      }

      const viewItem = {
        'item_name': p.name,
        'item_id': p.id,
        'price': p.isOnSale ? p.salePrice : p.price,
        'item_brand': 'Pupford',
        'item_list_name': 'Shop',
        'index': i + 1,
        'quantity': '1'
      };

      (p.categories || []).forEach((category, i) => {
        const categoryKey = i === 0 ? 'item_category' : `item_category${i + 1}`;
        viewItem[categoryKey] = category;
      });

      viewItemListEvent.ecommerce.items.push(viewItem);
    });

    if (productArrays[productArrays.length - 1].length < 2) {
      productArrays[productArrays.length - 1].push({});
    }

    const parentProductArrays = [[]];

    productArrays.forEach(p => {
      const lastProductArrayIndex = parentProductArrays.length - 1;

      if (parentProductArrays[lastProductArrayIndex].length === 2) {
        parentProductArrays.push([p]);
      } else {
        parentProductArrays[lastProductArrayIndex].push(p);
      }
    });

    if (parentProductArrays[parentProductArrays.length - 1].length < 2) {
      parentProductArrays[parentProductArrays.length - 1].push([[], []]);
    }

    setResults(parentProductArrays);

    window && window.dataLayer && window.dataLayer.push(viewItemListEvent);
  }, [filteredProducts, categories, resultsPerPage, currentPage, prices, customFilters]);

  const updateResultsPerPage = (amount) => {
    setResultsPerPage(amount);
    setCurrentPage(0);
  };

  const openProduct = async (product) => {
    setLoading(true);
    const db = getFirestore();

    try {
      const productSnapshot = await getDoc(doc(db, 'products-v2', product.id));
      const productData = productSnapshot.data();

      const availableProductQuantitiesResult = await axios.post(`${config.functionsCDN}/webApi/check-product-stock-quantities`, {
        productSkus: [productData.sku],
        productIdSkuMap: { [productData.id]: productData.sku },
      });

      if (availableProductQuantitiesResult.data && availableProductQuantitiesResult.data && availableProductQuantitiesResult.data[productData.sku]) {
        productData.availableQuantity = availableProductQuantitiesResult.data[productData.sku];
      } else {
        setLoading(false);
        setModalTitle('Error:');
        setModalText('There was an error loading this product, please try again.');
        return;
      }

      setLoading(false);
      setSelectedProduct({
        ...productData,
        price: product.price,
        msrp: product.msrp,
      });
      setProductModalOpen(true);
    } catch (e) {
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error loading this product, please try again.');
    }
  };

  const handleAddToCart = (quantity) => {
    setProductModalOpen(false);
    const product = selectedProduct;

    dispatch(addToCart({
      productId: product.id,
      hasMembership: false,
      name: product.name,
      image: product.media[0],
      path: product.path,
      sku: product.sku,
      isDigital: false,
      originalPrice: product.price,
      salePrice: product.price,
      price: product.price,
      subscription: null,
      subscriptionOptions: [],
      selectedSubscriptionIndex: 0,
      subscriptionOnly: false,
      tags: product.tags || [],
      categories: product.categories || [],
      shippingClass: product.shippingClass,
      quantity,
      taxCode: product.taxCode,
      customThankYouPagePath: product.customThankYouPagePath || '',
      customThankYouPagePriority: product.customThankYouPagePriority || 1,
    }));
  };

  const renderPagination = () => {
    if (numberOfPages === 1 || !resultsCount) {
      return <div></div>;
    }

    let pages = [];
    const current = currentPage + 1;
    let p;
  
    if (numberOfPages <= 5) {
      let i = 1;
      while (i <= numberOfPages) {
        pages.push(i);
        i++;
      }
    } else if (currentPage <= 3) {
      pages = [1, 2, 3, 4, 5];
    } else if (currentPage > (numberOfPages - 2)) {
      p = numberOfPages;
      pages = [p - 4, p - 3, p - 2, p - 1, p];
    } else {
      p = currentPage;
      pages = [p - 2, p - 1, p, p + 1, p + 2];
    }

    return (
      <div className="blogPaginationContainer">
        <div className="blogPaginationInner">
          {current === 1 ?
            <p className="blogPaginationArrows first">Back</p> :
            current === 2 ?
            <a onClick={() => {
              setCurrentPage(0);
              window.scrollTo(0, 0);
            }}>
              <p className="blogPaginationArrows first">Back</p>
            </a> :
            <a onClick={() => {
              setCurrentPage(currentPage - 1);
              window.scrollTo(0, 0);
            }}>
              <p className="blogPaginationArrows first">Back</p>
            </a>
          }
          {pages.includes(1) ? null :
            <>
              <a onClick={() => {
                setCurrentPage(0);
                window.scrollTo(0, 0);
              }}>
                <p className="additionalPageText">1</p>
              </a>
  
              <p className="ellipsisText">...</p>
            </>
          }
          {pages.map(p => {
            if (p === 1) {
              return (
                <div key={`page-key-${p}`}>
                  <a onClick={() => {
                    setCurrentPage(0);
                    window.scrollTo(0, 0);
                  }}>
                    <p className={p === current ? 'selected' : ''}>
                      1
                    </p>
                  </a>
                </div>
              );
            }
  
            return (
              <div key={`page-key-${p}`}>
                <a onClick={() => {
                  setCurrentPage(p - 1);
                  window.scrollTo(0, 0);
                }}>
                  <p className={p === current ? 'selected' : ''}>
                    {p}
                  </p>
                </a>
              </div>
            );
          })}
          {pages.includes(numberOfPages) ? null :
            <>
              <p className="ellipsisText">...</p>
  
              <a onClick={() => {
                setCurrentPage(numberOfPages - 1);
                window.scrollTo(0, 0);
              }}>
                <p className="additionalPageText">{numberOfPages}</p>
              </a>
            </>
          }
          {current >= numberOfPages ?
            <p className="blogPaginationArrows last">Next</p> :
            <a onClick={() => {
              setCurrentPage(currentPage + 1);
              window.scrollTo(0, 0);
            }}>
              <p className="blogPaginationArrows last">Next</p>
            </a>
          }
        </div>
      </div>
    );
  };

  return (
    <div className="Products">
      {(!productsInitialized || loading) ? <Loading /> : null}
      {!products.length ? null :
        <>
          <div className="productsContainer">
            <div className="mobileFiltersButtonContainer">
              <button className="mobileFiltersButton" onClick={() => { setShowMobileFilters(true) }}>
                Filter
                <img src="https://pupford.com/images/filter.png" width="12" />
              </button>
            </div>

            <div className="topRowContainer">
              <h2>{resultsCount} Results</h2>

              <div className="sortContainer">
                <label>Sort:</label>
                <select value={sort} onChange={(e) => { setSort(e.target.value) }}>
                  <option value={'featured'}>Featured</option>
                  <option value={'alphabetical'}>A-Z</option>
                  <option value={'priceLowHigh'}>Price: Low to High</option>
                  <option value={'priceHighLow'}>Price: High to Low</option>
                </select>
              </div>
            </div>

            <div className="productContentContainer">

              <div className="productFiltersContainer">
                <div className="filterTypeContainer">
                  <h3>Category</h3>

                  {categories.map((c, i) => {
                    return (
                      <div key={`category-${i}`} className="filterCheckboxRow">
                        <input
                          type="checkbox"
                          checked={c.selected}
                          onChange={(e) => {
                            const categoriesCopy = [ ...categories ];

                            categoriesCopy[i] = {
                              ...categoriesCopy[i],
                              selected: e.target.checked,
                            };

                            setCategories(categoriesCopy);
                            setCurrentPage(0);
                          }}
                        />
                        <label>{c.category}</label>
                      </div>
                    );
                  })}
                </div>

                {customFilters.map((cf, i) => {
                  return (
                    <div className="filterTypeContainer" key={`custom-filter-parent-${i}`}>
                      <h3>{cf.name}</h3>

                      {cf.options.map((o, j) => {
                        return (
                          <div key={`custom-filter-option-${i}-${j}`} className="filterCheckboxRow">
                            <input
                              type="checkbox"
                              checked={o.selected}
                              onChange={(e) => {
                                const filtersCopy = [ ...customFilters ];
                                const optionsCopy = [ ...cf.options ];

                                optionsCopy[j] = {
                                  ...optionsCopy[j],
                                  selected: e.target.checked,
                                };

                                filtersCopy[i] = {
                                  ...filtersCopy[i],
                                  options: optionsCopy,
                                };

                                setCustomFilters(filtersCopy);
                                setCurrentPage(0);
                              }}
                            />
                            <label>{o.name}</label>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div className="filterTypeContainer">
                  <h3>Price</h3>

                  {prices.map((p, i) => {
                    return (
                      <div key={`price-${i}`} className="filterCheckboxRow">
                        <input
                          type="checkbox"
                          checked={p.selected}
                          onChange={(e) => {
                            const pricesCopy = [ ...prices ];

                            pricesCopy[i] = {
                              ...pricesCopy[i],
                              selected: e.target.checked,
                            };

                            setPrices(pricesCopy);
                            setCurrentPage(0);
                          }}
                        />
                        <label>{p.display}</label>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="productResultsContainer">
                {results.map((r, i) => {
                  return (
                    <div key={`product-row-${i}`} className="productRow">
                      {r.map((innerRow, j) => {
                        return (
                          <div key={`product-row-inner-${i}-${j}`} className="productRowInner">
                            {innerRow.map((p, k) => {
                              if (!p.id) {
                                return (
                                  <div key={`empty-product-${i}-${j}-${k}`} className="emptyProductContainer">
                                  </div>
                                );
                              }

                              return (
                                <div key={p.id} className="productContainer">
                                  <a onClick={() => openProduct(p)}>
                                    <div className="productTopContainer">
                                      <img src={p.image && p.image.url} />
                                      <div className="productTitle">{p.name}</div>
                                      <p className="regularPrice">${p.price}</p>
                                    </div>

                                    <div className="shopButtonContainer">
                                      <button className="shopButtonMainContainer">
                                        View Details
                                      </button>
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div className="resultsPerPageContainer">
                  <div className="resultsPerPageInner">
                    <p>Results Per Page:</p>
                    <button
                      className={resultsPerPage === 40 ? 'selected' : ''}
                      onClick={() => {
                        updateResultsPerPage(40);
                        window.scrollTo(0, 0);
                      }}
                    >40</button>
                    <button
                      className={resultsPerPage === 60 ? 'selected' : ''}
                      onClick={() => {
                        updateResultsPerPage(60);
                        window.scrollTo(0, 0);
                      }}
                    >60</button>
                    <button
                      className={resultsPerPage === 80 ? 'selected' : ''}
                      onClick={() => {
                        updateResultsPerPage(80);
                        window.scrollTo(0, 0);
                      }}
                    >80</button>
                  </div>
                </div>
                
                <div className="paginationOuterContainer">
                  {renderPagination()}
                </div>
              </div>
            </div>

          </div>

          {!showMobileFilters ? null :
            <div className="mobileFiltersContainer">
              <div className="mobileFiltersTopContainer">
                <div className="mobileFiltersTitleContainer">
                  <p>Filters</p>
                </div>
                <button onClick={() => { setShowMobileFilters(false) }}>×</button>
              </div>

              <div className="mobileFiltersContent">
                <p className="resultCount">{resultsCount} Results</p>

                <div className="filterTypeContainer">
                  <h3>Category</h3>

                  {categories.map((c, i) => {
                    return (
                      <div key={`mobile-category-${i}`} className="filterCheckboxRow">
                        <input
                          type="checkbox"
                          checked={c.selected}
                          onChange={(e) => {
                            const categoriesCopy = [ ...categories ];

                            categoriesCopy[i] = {
                              ...categoriesCopy[i],
                              selected: e.target.checked,
                            };

                            setCategories(categoriesCopy);
                            setCurrentPage(0);
                          }}
                        />
                        <label>{c.category}</label>
                      </div>
                    );
                  })}
                </div>

                {customFilters.map((cf, i) => {
                  return (
                    <div className="filterTypeContainer" key={`custom-filter-parent-${i}`}>
                      <h3>{cf.name}</h3>

                      {cf.options.map((o, j) => {
                        return (
                          <div key={`custom-filter-option-${i}-${j}`} className="filterCheckboxRow">
                            <input
                              type="checkbox"
                              checked={o.selected}
                              onChange={(e) => {
                                const filtersCopy = [ ...customFilters ];
                                const optionsCopy = [ ...cf.options ];

                                optionsCopy[j] = {
                                  ...optionsCopy[j],
                                  selected: e.target.checked,
                                };

                                filtersCopy[i] = {
                                  ...filtersCopy[i],
                                  options: optionsCopy,
                                };

                                setCustomFilters(filtersCopy);
                                setCurrentPage(0);
                              }}
                            />
                            <label>{o.name}</label>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}

                <div className="filterTypeContainer">
                  <h3>Price</h3>

                  {prices.map((p, i) => {
                    return (
                      <div key={`price-${i}`} className="filterCheckboxRow">
                        <input
                          type="checkbox"
                          checked={p.selected}
                          onChange={(e) => {
                            const pricesCopy = [ ...prices ];

                            pricesCopy[i] = {
                              ...pricesCopy[i],
                              selected: e.target.checked,
                            };

                            setPrices(pricesCopy);
                            setCurrentPage(0);
                          }}
                        />
                        <label>{p.display}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          }
        </>
      }

      <Modal
        open={productModalOpen}
        close={() => {
          setProductModalOpen(false);
        }}
        title=""
        buttons={[]}
      >
        <div>
          <ProductModal
            product={selectedProduct}
            addToCart={handleAddToCart}
          />
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Products;
