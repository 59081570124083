import React, { useState, useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useDispatch, useSelector } from 'react-redux';

import './home.scss';
import { setShopSettings, updateShoppingCart } from '../../store/actions';
import Products from './Products';
import Orders from './Orders';
import ShoppingCart from './ShoppingCart';
import Modal from '../shared/Modal';
import PurchaseModal from '../shared/PurchaseModal';

function Home(props) {
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const user = useSelector(state => state.user);
  const stripeEnabled = useSelector(state => state.shopSettings.stripeEnabled);
  const paypalEnabled = useSelector(state => state.shopSettings.paypalEnabled);
  const [cartOpen, setCartOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    const db = getFirestore();

    try {
      const wholesaleProductsDataSnapshot = await getDoc(doc(db, 'wholesale', 'productData'));
      const wholesaleProductsData = wholesaleProductsDataSnapshot.data();
      const activeWholesaleProducts = wholesaleProductsData.products.filter(p => {
        return p.active;
      });

      if (!activeWholesaleProducts.length) {
        dispatch(setShopSettings({
          products: [],
          productsInitialized: true,
          filters: [],
        }));
        setModalTitle('Notice:');
        setModalText('There are currently no active wholesale products. Please contact us for assistance.');
        return;
      }

      const shopSettingsSnapshot = await getDoc(doc(db, 'config', 'shop-settings'));
      const shopSettings = shopSettingsSnapshot.data();

      const filtersSnapshot = await getDoc(doc(db, 'shop-filters', 'filters'));
      const filters = filtersSnapshot.data().filters;

      const wholesaleProductsMap = {};

      activeWholesaleProducts.forEach(p => {
        if (p.active) {
          wholesaleProductsMap[p.id] = p;
        }
      });

      const productsToUse = [];

      shopSettings.simplifiedProducts.forEach(p => {
        const wholesaleProduct = wholesaleProductsMap[p.id];

        if (wholesaleProduct) {
          productsToUse.push({
            ...p,
            price: wholesaleProduct.price,
            msrp: wholesaleProduct.msrp,
          });
        }
      });

      dispatch(setShopSettings({
        products: productsToUse,
        productsInitialized: true,
        filters,
        stripeEnabled: shopSettings.stripeEnabled,
        paypalEnabled: shopSettings.paypalEnabled,
      }));
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving wholesale products, please try again. If this problem persists, please contact us at hello@pupford.com for assistance.');
    }
  };

  const handleStripeSuccess = () => {
    setCheckoutOpen(false);
    dispatch(updateShoppingCart({
      ...cart,
      lastAdded: '',
      orderId: '',
      products: [],
      coupons: [],
      freeProducts: {},
    }));
    setModalTitle('Success:');
    setModalText('Your order has been placed. You will receive an order confirmation shortly.');
  };

  return (
    <div className="Home">
      <header>
        <div className="header-inner">
          <div className="header-left-container">
            <Link to="/">
              <img
                className="brand-image"
                src="https://pupford.com/wp-content/uploads/2018/06/pupford-logo.png"
                alt=""
              />
            </Link>

            <Link to="/products" className="header-link">
              Shop
            </Link>

            <Link to="/orders" className="header-link">
              Orders
            </Link>
          </div>

          <div>
            <button
              className="cart-button"
              onClick={() => setCartOpen(true)}
            >
              {!props.cart || !props.cart.products || !props.cart.products.length ? null :
                <div className="cart-amount">
                  <div className="cart-amount-inner">
                    <div className="cart-amount-text">
                      {props.cart.products.reduce((acc, curr) => {
                        return acc += +curr.quantity;
                      }, 0)}
                    </div>
                  </div>
                </div>
              }

              <img src="https://pupford.b-cdn.net/website-content%2Fcart.png?alt=media&token=482e5d94-586f-400d-a1ea-29a60d4d92c8" />
            </button>
          </div>
        </div>
      </header>

      <ShoppingCart
        cart={props.cart}
        open={cartOpen}
        closeNav={() => setCartOpen(false)}
        changeQuantity={props.changeQuantity}
        removeFromCart={props.removeFromCart}
        openCheckout={() => {
          setCartOpen(false);
          setCheckoutOpen(true);
        }}
      />

      <Switch>
        <Route path="/products" component={Products} />
        <Route path="/orders" component={Orders} />
        <Route component={Products} />
      </Switch>

      {!checkoutOpen ? null :
        <div className="purchase-modal-container">
          <div className="purchase-modal-body-container">
            <div
              className="close-button"
              onClick={() => {
                setCheckoutOpen(false);
              }}
            >
              <i aria-hidden="true">×</i>
            </div>

            <h3 className="title">Checkout</h3>

            <PurchaseModal
              user={user}
              cart={cart}
              stripeEnabled={stripeEnabled}
              paypalEnabled={false}
              cancelPressed={() => {
                setCheckoutOpen(false);
              }}
              handleStripeSuccess={handleStripeSuccess}
            />
          </div>
        </div>
      }

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Home;
