import React from 'react';

import Loader from '../Loader';
import './loadingMessage.scss';

function LoadingMessage(props) {
  return (
    <div className="LoadingMessage">
      <div className="loading-inner">
        <Loader size={2}/>
        <p>{props.message}</p>
      </div>
    </div>
  );
}

export default LoadingMessage;
