import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import axios from 'axios';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore';

import './purchaseModal.scss';
import { config } from '../../../config';
import Payment from '../Payment';
import LoadingMessage from '../LoadingMessage';
import { unitedStates, canada } from '../../../lib/statesAndCountries';
import { withStripe } from '../withStripe';
import infoTriangleRed from '../../../images/info-triangle-red.svg';

const shippingCountries = [
  {
    name: 'United States',
    value: 'US',
  },
];

function PurchaseModal({
  cart,
  user,
  stripeEnabled,
  paypalEnabled,
  cancelPressed,
  handleStripeSuccess,
  stripe,
  elements,
}) {
  const [loadingMessage, setLoadingMessage] = useState('');
  const [shippingInfo, setShippingInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    addressSecondary: '',
    city: '',
    zip: '',
    country: 'US',
    state: unitedStates[0].value,
  });
  const [shippingStates, setShippingStates] = useState(unitedStates);
  const [selectedPaymentType, setSelectedPaymentType] = useState();
  const [useExistingCard, setUseExistingCard] = useState(false);
  const [stripeCards, setStripeCards] = useState([]);
  const [stripeCustomerId, setStripeCustomerId] = useState('');
  const [selectedStripeCardIndex, setSelectedStripeCardIndex] = useState(0);
  const [stripeFieldsComplete, setStripeFieldsComplete] = useState({
    number: false,
    expiration: false,
    cvc: false,
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [errorMessageTitle, setErrorMessageTitle] = useState('');
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const total = cart.products.reduce((acc, curr) => {
      return acc += (+curr.price * curr.quantity);
    }, 0);
    setPrice(total);
  }, [cart]);

  useEffect(() => {
    if (user && user.shipping) {
      setShippingInfo({
        ...shippingInfo,
        country: user.shipping.country || 'US',
        state: user.shipping.state || unitedStates[0].value,
        zip: user.shipping.zip || '',
      });
    }

    if (user.stripeCards && Array.isArray(user.stripeCards) && user.stripeCustomerId) {
      user.stripeCards = user.stripeCards.filter(c => {
        const date = moment([c.exp_year, c.exp_month - 1]);
        const expirationDate = moment(date).endOf('month');
        const currentDate = moment();

        return currentDate < expirationDate;
      });

      setStripeCards(user.stripeCards);
      setStripeCustomerId(user.stripeCustomerId);
      setUseExistingCard(true);
    }
  }, [user]);

  useEffect(() => {
    if (stripeEnabled) {
      setSelectedPaymentType('stripe');
    } else if (paypalEnabled) {
      setSelectedPaymentType('paypal');
    }
  }, [stripeEnabled, paypalEnabled]);

  const handleCountryChange = (e) => {
    const country = e.target.value;
    const updateData = {
      ...shippingInfo,
      country,
    };

    if (country === 'CA') {
      setShippingStates(canada);
      updateData.state = canada[0].value;
    }

    if (country === 'US') {
      setShippingStates(unitedStates);
      updateData.state = unitedStates[0].value;
    } else {
      updateData.zip = '';
    }

    setShippingInfo(updateData);
  };

  const placeOrder = async () => {
    const errors = [];

    if (shippingInfo.country === 'US' && shippingInfo.zip.length < 5) {
      errors.push('A valid zip code is required.');
    }

    if (selectedPaymentType === 'stripe' && !useExistingCard) {
      if (!stripeFieldsComplete.number) {
        errors.push('Card number is required.');
      }

      if (!stripeFieldsComplete.expiration) {
        errors.push('Card expiration date is required.');
      }

      if (!stripeFieldsComplete.cvc) {
        errors.push('Card security code is required.');
      }
    }

    if (!shippingInfo.firstName) {
      errors.push('First name is required.');
    }

    if (!shippingInfo.lastName) {
      errors.push('Last name is required.');
    }

    if (!shippingInfo.address) {
      errors.push('Address is required.');
    }

    if (!shippingInfo.city) {
      errors.push('City is required.');
    }

    const productSkus = [];
    const productIdSkuMap = {};

    const products = cart.products.map((p) => {
      if (!p.isDigital) {
        productSkus.push(p.sku);
        productIdSkuMap[p.productId] = p.sku;
      }

      const productData = {
        id: p.productId,
        name: p.name,
        quantity: parseInt(p.quantity),
        sku: p.sku,
        originalPrice: p.originalPrice,
        paidPrice: p.price,
        isDigital: p.isDigital,
        categories: p.categories || [],
        path: p.path || '',
        imageUrl: p.image ? p.image.url : '',
        isGiftCard: p.isGiftCard || false,
      };

      return productData;
    });

    // Resetting error message, this will only be displayed if there are any error messages
    setErrorMessageTitle('We were unable to place your order. Please update the following fields and try again.');
    setErrorMessages(errors);

    if (errors.length) {
      return;
    }

    setLoadingMessage('Placing order...');

    try {
      const outOfStockProductNames = [];
      const insufficientStockProducts = [];

      let availableProductQuantitiesResult = {};

      // only query stock quantities if there are physical products
      if (productSkus.length) {
        availableProductQuantitiesResult = await axios.post(`${config.functionsCDN}/webApi/check-product-stock-quantities`, {
          productSkus,
          productIdSkuMap,
        });
      }

      // in case of digital products only quantityData will be undefined and is not used
      const quantityData = availableProductQuantitiesResult.data;

      const combinedProductsMap = {};

      products.forEach((p) => {
        if (p.isDigital) {
          return;
        }

        if (combinedProductsMap.hasOwnProperty(p.sku)) {
          combinedProductsMap[p.sku].quantity += p.quantity;
        } else {
          combinedProductsMap[p.sku] = {
            name: p.name,
            quantity: p.quantity,
          };
        }
      });

      for (let key in combinedProductsMap) {
        const available = quantityData[key] || 0;

        if (available <= 0) {
          outOfStockProductNames.push(combinedProductsMap[key].name);
        } else if (available < combinedProductsMap[key].quantity) {
          insufficientStockProducts.push({
            name: combinedProductsMap[key].name,
            available,
          });
        }
      }

      if (outOfStockProductNames.length || insufficientStockProducts.length) {
        const stockErrorMessages = [];
        outOfStockProductNames.forEach(p => {
          stockErrorMessages.push(`${p}: available - 0`);
        });
        insufficientStockProducts.forEach(p => {
          stockErrorMessages.push(`${p.name}: available - ${p.available}`);
        });

        setLoadingMessage('');
        setErrorMessageTitle('The following products have insufficient stock.');
        setErrorMessages(stockErrorMessages);
        return;
      }      
    } catch (e) {
      console.log('error', e);
      setLoadingMessage('');
      setErrorMessageTitle('The following errors occurred.');
      setErrorMessages([
        'There was an error processing your order, please try again. If this problem persists, please contact us.',
      ]);
      return;
    }

    const auth = getAuth();

    const total = +price;
    const currentUser = auth.currentUser;
    const email = currentUser.email;
    const orderData = {
      wholesale: true,
      status: 'PENDING_PAYMENT',
      hasOrderBump: false,
      hasFreeProduct: false,
      userId: currentUser.uid,
      userEmail: email,
      email,
      phone: '',
      products,
      couponsUsed: [],
      total: total.toFixed(2),
      subtotal: (+price).toFixed(2),
      taxAmount: '0.00',
      shippingAmount: '0.00',
      billing: {
        ...shippingInfo,
      },
      shipping: shippingInfo,
      subscriptions: [],
      updated: Timestamp.fromDate(new Date()),
      created: Timestamp.fromDate(new Date()),
      thankYouPage: '',
    };

    const db = getFirestore();

    if (selectedPaymentType === 'paypal') {
      setLoadingMessage('Connecting to PayPal. Do not refresh the page.');

      try {
        const order = await addDoc(collection(db, 'orders-v2'), {
          ...orderData,
          paymentType: 'paypal',
        });

        const result = await axios.post(`${config.functionsCDN}/webApi/ba-token`, {
          returnUrl: `${window.location.origin}/order-success?id=${order.id}`,
          cancelUrl: `${window.location.origin}/order-cancel?id=${order.id}`,
        });
        const data = result.data;

        if (data && data.links && data.links[0]) {
          window.location.href = data.links[0].href;
        } else {
          setLoadingMessage('');
          setErrorMessageTitle('The following errors occurred.');
          setErrorMessages([
            'There was an error connecting to PayPal, please try again. If this problem persists, please contact us.',
          ]);
        }
      } catch (err) {
        setLoadingMessage('');
        setErrorMessageTitle('The following errors occurred.');
        setErrorMessages([
          'There was an error connecting to PayPal, please try again. If this problem persists, please contact us.',
        ]);
      }
    } else if (selectedPaymentType === 'stripe') {
      setLoadingMessage('Placing order. Do not refresh the page.');

      try {
        const order = await addDoc(collection(db, 'orders-v2'), {
          ...orderData,
          paymentType: 'stripe',
        });

        const orderId = order.id;

        const stripeData = {
          orderId,
          currentUserEmail: email,
          userId: currentUser.uid,
          type: useExistingCard ? 'use-token' : 'new-card',
          token: '',
          amount: total.toFixed(2),
          stripeCardId: '',
          stripeCustomerId,
        };

        if (useExistingCard) {
          stripeData.stripeCardId = stripeCards[selectedStripeCardIndex].id;
        } else {
          const cardNumberElement = elements.getElement(CardNumberElement);

          const {error, token} = await stripe.createToken(cardNumberElement);

          if (error || !token.id) {
            setLoadingMessage('');
            setErrorMessageTitle('Error:');
            setErrorMessages([
              'There was an error connecting to Stripe, please try again. If this problem persists, please contact us.',
            ]);
            return;
          } else {
            stripeData.token = token.id;
          }
        }

        const result = await axios.post(`${config.functionsCDN}/webApi/s-create-order`, stripeData);

        if (result.data.error) {
          setLoadingMessage('');
          setErrorMessageTitle('Error:');
          setErrorMessages([
            result.data.error,
          ]);
          return;
        }

        setLoadingMessage('');
        handleStripeSuccess();
      } catch (err) {
        setLoadingMessage('');
        setErrorMessageTitle('Error:');
        setErrorMessages([
          'There was an error creating your order, please try again. If this problem persists, please contact us.',
        ]);
      }
    }
  };

  return (
    <div className="PurchaseModal">
      {!loadingMessage ? null :
        <div className="loading-message-container">
          <LoadingMessage message={loadingMessage} />
        </div>
      }

      <div className="top-section">
        <div className="price-section well">
          {cart.products.map((p, i) => {
            return (
              <div className="price-row" key={`price-row-${i}`}>
                <p>{p.name}</p>
                <p>{p.quantity} <span className="multiply">×</span> ${p.price}</p>
              </div>
            );
          })}

          <div className="total-row">
            <p>Total</p>
            <p className="total-text">${(+price).toFixed(2)}</p>
          </div>
        </div>
      </div>

      <div className="payment-details-container">
        <div className="input-row">
          <div className="input-container half-width">
            <label>First Name *</label>
            <input
              type="text"
              placeholder="First name"
              value={shippingInfo.firstName}
              onChange={(e) => {
                setShippingInfo({
                  ...shippingInfo,
                  firstName: e.target.value,
                });
              }}
            />
          </div>

          <div className="input-container half-width">
            <label>Last Name *</label>
            <input
              type="text"
              placeholder="Last name"
              value={shippingInfo.lastName}
              onChange={(e) => {
                setShippingInfo({
                  ...shippingInfo,
                  lastName: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="input-container">
          <label>Address *</label>
          <input
            type="text"
            placeholder="Address"
            value={shippingInfo.address}
            onChange={(e) => {
              setShippingInfo({
                ...shippingInfo,
                address: e.target.value,
              });
            }}
          />
        </div>

        <div className="input-row">
          <div className="input-container half-width">
            <label>Suite, unit etc</label>
            <input
              type="text"
              placeholder="Suite, unit etc"
              value={shippingInfo.addressSecondary}
              onChange={(e) => {
                setShippingInfo({
                  ...shippingInfo,
                  addressSecondary: e.target.value,
                });
              }}
            />
          </div>

          <div className="input-container half-width">
            <label>City *</label>
            <input
              type="text"
              placeholder="City"
              value={shippingInfo.city}
              onChange={(e) => {
                setShippingInfo({
                  ...shippingInfo,
                  city: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="input-container">
          <label>Country *</label>
          <select value={shippingInfo.country} onChange={handleCountryChange}>
            {shippingCountries.map((c, i) => {
              return <option key={`shipping-country-${i}`} value={c.value}>{c.name}</option>
            })}
          </select>
        </div>

        <div className="input-row">
          {(shippingInfo.country !== 'US' && shippingInfo.country !== 'CA') ? null :
            <div className={`input-container ${shippingInfo.country !== 'CA' ? 'half-width' : 'full-width'}`}>
              <label>State *</label>
              <select value={shippingInfo.state} onChange={(e) => {
                setShippingInfo({
                  ...shippingInfo,
                  state: e.target.value,
                });
              }}>
                {shippingStates.map((s, i) => {
                  return <option key={`shipping-state-${i}`} value={s.value}>{s.name}</option>
                })}
              </select>
            </div>
          }

          {shippingInfo.country !== 'US' ? null :
            <div className="input-container half-width">
              <label>Postcode *</label>
              <input
                type="text"
                placeholder="Postcode"
                value={shippingInfo.zip}
                onChange={(e) => {
                  setShippingInfo({
                    ...shippingInfo,
                    zip: e.target.value,
                  });
                }}
              />
            </div>
          }
        </div>

        <div className="payment-section">
          <Payment
            stripeEnabled={stripeEnabled}
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            useExistingCard={useExistingCard}
            stripeCards={stripeCards}
            stripeFieldsComplete={stripeFieldsComplete}
            setStripeFieldsComplete={setStripeFieldsComplete}
            selectedStripeCardIndex={selectedStripeCardIndex}
            setSelectedStripeCardIndex={setSelectedStripeCardIndex}
            setUseExistingCard={setUseExistingCard}
            paypalEnabled={paypalEnabled}
          />
        </div>
      </div>

      {!errorMessages.length ? null :
        <div className="error-container">
          <div className="error-icon-container">
            <img src={infoTriangleRed} alt="error" />
          </div>
          <div>
            <p><strong>{errorMessageTitle}</strong></p>

            <ul>
              {errorMessages.map((error, i) => {
                return <li key={`error-${i}`}>{error}</li>;
              })}
            </ul>
          </div>
        </div>
      }

      <div className="bottom-buttons-container">
        <button
          className="place-order-button"
          onClick={placeOrder}
        >
          Place Order
        </button>

        <div className="cancel-text-container">
          <a
            className="cancel-text"
            onClick={() => {
              setErrorMessages([]);
              setErrorMessageTitle('');
              cancelPressed();
            }}
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
}

export default withStripe(PurchaseModal);
