import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

import './orders.scss';
import Modal from '../../shared/Modal';

function Orders(props) {
  const user = useSelector(state => state.user);
  const [errorMessage, setErrorMessage] = useState('');
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (user.uid) {
      getOrders();
    }
  }, [user]);

  const getOrders = async () => {
    try {
      const auth = getAuth();
      const db = getFirestore();
      const q = query(collection(db, 'orders-v2'), where('userId', '==', auth.currentUser.uid));
      const ordersSnapshot = await getDocs(q);
      let ordersData = ordersSnapshot.docs.map(doc => {
        const data = doc.data();

        return {
          ...data,
          status: data.status === 'PENDING_ONE_CLICK_UPSELL' ? 'PROCESSING' : data.status,
          id: doc.id,
          subscriptions: [],
        };
      }).sort((a, b) => {
        const first = a.created.seconds;
        const second = b.created.seconds;

        return second - first;
      });

      setOrders(ordersData);
    } catch (e) {
      console.log('error', e);
      setErrorMessage('There was an error retrieving your orders, please try again later. If this problem persists, please contact us.');
    }
  };

  const titleCase = (str) => {
    return str.toLowerCase().split(' ').map(word => {
      return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
  };

  const getOrderStatusStyle = (status) => {
    switch (status) {
      case 'PROCESSING':
        return { color: '#00afcc' };
      case 'COMPLETED':
        return { color: '#2BB673' };
      case 'CANCELED':
        return { color: '#ff3312' };
      default:
        return '';
    }
  };

  return (
    <div className="Orders">
      <div className="ordersOuterContainer">
        <h1>Orders</h1>

        <div className="ordersContainer">
          {orders.map((order) => {
            return (
              <div key={order.id} className="order well well-secondary">
                <div className="topContainer">
                  <div className="date">{moment(order.created.seconds * 1000).format('lll')}</div>
                  <div><span className="status">Status:</span> <span className={getOrderStatusStyle(order.status)}>{titleCase(order.status.replace(/_/g, ' '))}</span></div>
                </div>

                <div className="orderSummary">
                  <h2>Summary</h2>

                  <div className="divider divider-light"></div>

                  {!order.products ? null :
                    <table>
                      <thead>
                        <tr>
                          <td>Product Name</td>
                          <td>Quantity</td>
                          <td>Price</td>
                        </tr>
                      </thead>
                      <tbody>
                        {order.products.map((p, i) => {
                          return (
                            <tr key={`order-item-${i}`}>
                              <td>
                                {p.name}
                              </td>
                              <td>
                                {p.quantity}
                              </td>
                              <td>
                                ${p.paidPrice}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  }

                  <div className="totalsContainer">
                    <div className="totalsInner">
                      <div className="totalLine">
                        <p>Subtotal:</p>
                        <p>${order.subtotal}</p>
                      </div>
                      <div className="totalLine">
                        <p>Shipping:</p>
                        <p>${order.shippingAmount}</p>
                      </div>
                      <div className="totalLine">
                        <p>Tax:</p>
                        <p>${order.taxAmount}</p>
                      </div>
                      {!order.rewardsDollarsUsed ? null :
                        <div className="totalLine rewardsLine">
                          <p>Rewards Dollars:</p>
                          <p>- ${(+order.rewardsDollarsUsed).toFixed(2)}</p>
                        </div>
                      }
                      <div className="totalLine grandTotal">
                        <p>Total:</p>
                        <p>${order.total}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Modal
        open={!!errorMessage}
        close={() => { setErrorMessage('') }}
        title="Error:"
        buttons={[
          <button key="modal-close" onClick={() => { setErrorMessage('') }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{errorMessage}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Orders;
