import {
  ADD_TO_CART,
  UPDATE_PRODUCT_QUANTITY,
  REMOVE_FROM_CART,
  REPOPULATE_CART,
  UPDATE_SHOPPING_CART,
} from '../actions/types';

import { updateShoppingCart } from '../../lib/shopping';

const initialState = {
  lastAdded: '',
  orderId: '',
  products: [],
  coupons: [],
  freeProducts: {},
  userId: '',
};

export default (state = initialState, action) => {
  let products = [];
  let updated = {};

  switch (action.type) {
    case ADD_TO_CART:
      products = [ action.payload, ...state.products ];

      updated = {
        ...state,
        lastAdded: action.payload.name,
        products,
      };

      updateShoppingCart(updated);
      return updated;
    case UPDATE_PRODUCT_QUANTITY:
      products = [ ...state.products ];
      products[action.payload.index].quantity = +action.payload.quantity;

      updated = {
        ...state,
        lastAdded: action.payload.name,
        products,
      };

      updateShoppingCart(updated);
      return updated;
    case REMOVE_FROM_CART:
      products = [ ...state.products ];
      products.splice(action.payload, 1);

      updated = {
        ...state,
        products,
      };

      updateShoppingCart(updated);
      return updated;
    case UPDATE_SHOPPING_CART:
      updated = {
        ...action.payload,
      };

      updateShoppingCart(updated);
      return updated;
    case REPOPULATE_CART:
      return {
        lastAdded: '',
        orderId: '',
        ...action.payload,
      };
    default:
      return state;
  }
};
