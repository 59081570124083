import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SignIn from './SignIn';

function Auth({}) {
  return (
    <div>
      <Switch>
        <Route path="/signin" component={SignIn} />
        <Route component={SignIn} />
      </Switch>
    </div>
  );
}

export default Auth;
