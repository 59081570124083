import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './shoppingCart.scss';

import plusIcon from '../../../images/plus.svg';
import minusIcon from '../../../images/minus.svg';

function ShoppingCart({
  cart,
  open,
  closeNav,
  changeQuantity,
  removeFromCart,
  openCheckout,
}) {
  const [fixedCartDiscountAmount, setFixedCartDiscountAmount] = useState(0);

  useEffect(() => {
    if (cart && cart.coupons && cart.coupons.length) {
      let calculatedFixedCartDiscount = 0;

      cart.coupons.forEach((c) => {
        if (c.type === 'Fixed cart discount') {
          calculatedFixedCartDiscount = calculatedFixedCartDiscount + +c.amount;
        }
      });

      setFixedCartDiscountAmount(calculatedFixedCartDiscount);
    }
  }, [cart]);

  return (
    <div className={`ShoppingCart${!open ? ' closed' : ''}`}>
      <div className="cart-column">
        <div className="top-section">
          {!cart.products || !cart.products.length ?
            <p>Shopping Cart</p> :
            <p>Cart Subtotal: ${(cart.products.reduce((acc, curr) => {
              return acc += (+curr.price * curr.quantity);
            }, 0) - fixedCartDiscountAmount).toFixed(2)}</p>
          }

          <i onClick={closeNav}>×</i>
        </div>
        <div className="cart-content">
          {!cart.products || !cart.products.length ?
            <div className="empty-cart-container">
              <p>Your cart is empty</p>

              <Link to="/products">
                <button className="success" onClick={closeNav}>Keep Shopping</button>
              </Link>
            </div> :
            <div className="cart-products-container">
              {cart.products.map((product, i) => {
                return (
                  <div key={`cart-product-${i}`} className="cart-product">
                    {!product.image || !product.image.url ? null :
                      <div className="product-image-container">
                        <img src={product.image.url} alt="" />
                      </div>
                    }

                    <div className="product-center-container">
                      <p>{product.name}</p>

                      {(product.isDigital || parseFloat(product.price) === 0) ? null :
                        <div className="amount-container">
                          <div className="amount-container-inner">
                            <div className="quantity-button">
                              <button
                                className="quantity-button-inner"
                                onClick={() => {
                                  const quantity = +product.quantity - 1;

                                  if (quantity === 0) {
                                    return;
                                  }

                                  changeQuantity(i, quantity);
                                }}
                              >
                                <img src={minusIcon} alt="minus" width="10" />
                              </button>
                            </div>
                            <div className="quantity-amount">{product.quantity}</div>
                            <div className="quantity-button">
                              <button
                                className="quantity-button-inner"
                                onClick={() => {
                                  const quantity = +product.quantity + 1;

                                  changeQuantity(i, quantity);
                                }}
                              >
                                <img src={plusIcon} alt="add" width="10" />
                              </button>
                            </div>
                          </div>
                        </div>
                      }
                    </div>

                    <div className="product-right-container">
                      {parseFloat(product.price) === 0 ?
                        <p className="price-free">Try Free</p> :
                        <p>${(+product.price * product.quantity).toFixed(2)}</p>
                      }
                      <a onClick={() => {
                        removeFromCart(i);

                        const gtag = window.gtag || function() {};
                        const product = cart.products[i];
                        gtag('event', 'remove_from_cart', {
                          items: [
                            {
                              id: product.productId,
                              name: product.name,
                              brand: 'Pupford',
                              category: product.categories ? product.categories.join('/') : '',
                              price: product.price,
                            },
                          ],
                        });
                      }}>×</a>
                    </div>
                  </div>
                );
              })}
            </div>
          }
        </div>

        {!cart.products || !cart.products.length ? null :
          <div className="checkout-button-container">
            <button onClick={openCheckout}>Check Out</button>
          </div>
        }
      </div>
    </div>
  );
}

export default ShoppingCart;
