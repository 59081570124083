import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, Timestamp } from 'firebase/firestore';

export const updateShoppingCart = async (cart) => {
  try {
    const auth = getAuth();
    const uid = auth.currentUser.uid;
    const db = getFirestore();
    await setDoc(doc(db, 'shopping-carts', uid), {
      coupons: cart.coupons,
      products: cart.products,
      freeProducts: cart.freeProducts,
      userId: cart.userId,
      dateUpdated: Timestamp.fromDate(new Date()),
    });
  } catch (e) {
    // fail silently
  }
};
