import {
  SET_SHOP_SETTINGS,
} from '../actions/types';

const defaultState = {
  products: [],
  productsInitialized: false,
  filters: [],
  stripeEnabled: false,
  paypalEnabled: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_SHOP_SETTINGS:
      return action.payload;
    default:
      return state;
  }
};
