import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from '@firebase/auth';

import './signIn.scss';
import Modal from '../../shared/Modal';
import { authCodesMap } from '../authCodesMap';

function SignIn(props) {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    if (props && props.location && props.location.search) {
      const queryString = props.location.search;
      const query = {};
      const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
      for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }

      if (query.authResult && query.authResult === 'UNAUTHORIZED') {
        setModalTitle('Notice:');
        setModalText('You are not authorized to access this area. If you need assistance, please contact us at hello@pupford.com.');
      }
    }
  }, [props.location]);

  const signIn = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
      history.push('/shop');
    } catch (e) {
      const errorMessage = authCodesMap[e.code] || 'An error occurred signing you in. Please try again.';
      setModalTitle('Error:');
      setModalText(errorMessage);
    }
  };

  return (
    <div className="SignIn">
      <div className="form-container">
        <div className="well auth-well">
          <div className="logo-container">
            <img
              src="https://pupford.com/wp-content/uploads/2018/06/pupford-logo.png"
              alt="Pupford Logo"
              className="logo"
            />
          </div>

          <h1>Wholesale Sign In</h1>

          <form onSubmit={signIn}>
            <div className="input-container">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email"
              />
            </div>

            <div className="input-container">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </div>

            <button
              type="submit"
            >
              Sign In
            </button>
          </form>

          <div className="forgot-password-link-container">
            <a href={`https://pupford.com/forgot-password/`} target="_blank">
              Forgot Password
            </a>
          </div>

          <div className="copyright-container">
            <p>Copyright © {new Date().getFullYear()}, Pupford LLC - All Rights Reserved</p>
          </div>
        </div>
      </div>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default SignIn;
