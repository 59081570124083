import React from 'react';
import ReactDOM from 'react-dom';

import './modal.scss';

function Modal({ open, close, title, children, buttons }) {

  if (!open) {
    return <div></div>;
  }

  return ReactDOM.createPortal(
    <div className="Modal">
      <div className="modal-body">
        <div className="close-button" onClick={close}>
          <i aria-hidden="true">×</i>
        </div>

        {!title ? null : <h3 className="title">{title}</h3>}
        <div className="modal-content">{children}</div>
        {!buttons ? null : <div className="buttons-container">{buttons}</div>}
      </div>
    </div>,
    document.querySelector('#modalTarget')
  );
}

export default Modal;
