import React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import './payment.scss';
import visa from '../../../images/visa.svg';
import amex from '../../../images/amex.svg';
import mastercard from '../../../images/mastercard.svg';
import discover from '../../../images/discover.svg';
import jcb from '../../../images/jcb.svg';
import diners from '../../../images/diners.svg';

const stripeOptions = {
  style: {
    base: {
      fontSize: '16px',
      fontFamily: 'Visby, sans-serif',
      color: '#404040',
      fontWeight: 400,
      '::placeholder': {
        color: '#aaa',
        fontWeight: 400,
      },
    },
    invalid: {
      color: '#ff3312',
    },
  },
};

export default function Payment({
  stripeEnabled,
  selectedPaymentType,
  setSelectedPaymentType,
  useExistingCard,
  stripeCards,
  stripeFieldsComplete,
  selectedStripeCardIndex,
  setSelectedStripeCardIndex,
  setUseExistingCard,
  setStripeFieldsComplete,
  paypalEnabled,
}) {
  return (
    <div className="paymentContainer">
      {!stripeEnabled ? null :
        <div className="paymentTypeContainer">
          <div className="paymentTypeHeading">
            <div className="paymentHeadingButtonContainer">
              <button
                className={selectedPaymentType === 'stripe' ? 'selected' : ''}
                onClick={() => {
                  if (selectedPaymentType === 'stripe') {
                    return;
                  }

                  setSelectedPaymentType('stripe');
                }}
              >
                <div className="selectedProductIndicator"></div>
              </button>
            </div>
            <p>Credit Card</p>
            <div className="cardContainer">
              <img height="23" src={visa} alt="Visa" />
              <img height="23" src={amex} alt="AMEX" />
              <img height="23" src={mastercard} alt="Mastercard" />
              <img height="23" src={discover} alt="Discover" />
              <img height="23" src={jcb} alt="JCB" />
              <img height="23" src={diners} alt="Diners" />
            </div>
          </div>

          <div className={`paymentTypeBody ${selectedPaymentType === 'stripe' ? 'show' : ''}`}>
            <div className={!useExistingCard ? 'stripeRegionHidden' : ''}>
              <table>
                <thead>
                  <tr>
                    <td></td>
                    <td>Brand</td>
                    <td>Last 4</td>
                    <td>Expiration</td>
                  </tr>
                </thead>
                <tbody>
                  {stripeCards.map((card, i) => {
                    return (
                      <tr key={`card-${i}`} className="stripeCardRow">
                        <td>
                          <button
                            className={selectedStripeCardIndex === i ? 'selected' : ''}
                            onClick={() => { setSelectedStripeCardIndex(i) }}
                          >
                            <div className="selectedCardIndicator"></div>
                          </button>
                          </td>
                        <td>{card.brand}</td>
                        <td>{card.last4}</td>
                        <td>{card.exp_month}/{card.exp_year}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <a onClick={() => { setUseExistingCard(false) }}>Use a different card</a>
            </div>

            <div className={useExistingCard ? 'stripeRegionHidden' : ''}>
              <div className="stripeRow">
                <div className="stripeInputContainer">
                  <label>Card Number *</label>
                  <div className="stripeInputBorder">
                    <CardNumberElement
                      options={stripeOptions}
                      onChange={(e) => {
                        if (e.complete) {
                          setStripeFieldsComplete({
                            ...stripeFieldsComplete,
                            number: true,
                          });
                        } else {
                          setStripeFieldsComplete({
                            ...stripeFieldsComplete,
                            number: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="stripeRow">
                <div className="stripeInputContainer">
                  <label>Expiry Date *</label>
                  <div className="stripeInputBorder">
                    <CardExpiryElement
                      options={stripeOptions}
                      onChange={(e) => {
                        if (e.complete) {
                          setStripeFieldsComplete({
                            ...stripeFieldsComplete,
                            expiration: true,
                          });
                        } else {
                          setStripeFieldsComplete({
                            ...stripeFieldsComplete,
                            expiration: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="stripeInputContainer">
                  <label>Card Code (CVC) *</label>
                  <div className="stripeInputBorder">
                    <CardCvcElement
                      options={stripeOptions}
                      onChange={(e) => {
                        if (e.complete) {
                          setStripeFieldsComplete({
                            ...stripeFieldsComplete,
                            cvc: true,
                          });
                        } else {
                          setStripeFieldsComplete({
                            ...stripeFieldsComplete,
                            cvc: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              {!stripeCards.length ? null :
                <a onClick={() => { setUseExistingCard(true) }}>Use existing card</a>
              }
            </div>
          </div>
        </div>
      }
      {!paypalEnabled ? null :
        <div className="paymentTypeContainer lastPaymentTypeContainer">
          <div className="paymentTypeHeading">
            <div className="paymentHeadingButtonContainer">
              <button
                className={selectedPaymentType === 'paypal' ? 'selected' : ''}
                onClick={() => {
                  if (selectedPaymentType === 'paypal') {
                    return;
                  }

                  setSelectedPaymentType('paypal');
                }}
              >
                <div className="selectedProductIndicator"></div>
              </button>
            </div>
            <p>PayPal</p>
            <img width="100" height="34.5" src="https://www.paypalobjects.com/webstatic/mktg/logo/AM_mc_vs_dc_ae.jpg" alt="PayPal acceptance mark" />
          </div>

          <div className={`paymentTypeBody ${selectedPaymentType === 'paypal' ? 'show' : ''}`}>
            <p>Pay via PayPal with your credit card or with a PayPal account.</p>
          </div>
        </div>
      }
    </div>
  );
}
